.InlineErrorModuleContainer {
    display: flex;
    color: var(--red-color);
    direction: rtl;
    width: 100%;
}

.ErrorIconMobile {
    font-size: 6vw;
    padding-left: 2vw;
}

.InlineErrorContentMobile {
    direction: rtl;
    font-family: IRANSans, serif;
    font-size: 3.5vw;
    font-weight: bold;
    margin-top: 0;
}

.ErrorIconDesktop {
    font-size: 1.5vw;
    padding-left: 1vw;
    padding-bottom: 1vw;
    text-align: right;
}

.InlineErrorContentDesktop {
    margin-top: 0;
    direction: rtl;
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
}