.HeaderContainer {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    direction: rtl;
    width: 100%;
    height: 8vh;
    top: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--background-grey-color);
    z-index: 10;
    right: 0;
}

.rightSection {
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    width: 75%;
}

.rightSectionNotificationPanelOpen {
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    width: 30%;
}

.platformName {
    font-family: IRANSans, serif;
    font-size: 1.3vw;
    font-weight: bold;
    color: var(--primary-dark-color);
    padding-right: 3vw;
    text-decoration: none;
}

.Menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 4vw;
}

.MenuNotificationPanelOpen {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 4vw;
    margin-right: auto;
}

.MenuItem {
    font-family: IRANSans, serif;
    font-size: 1vw;
    color: var(--primary-dark-color);
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    display: block;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.MenuItem:last-child {
    color: var(--red-color);
}

.BellIconContainer {
    position: relative;
    display: inline;
    padding-left: 3vw;
    direction: rtl;
}

.BellIcon,
.BellIconOpened {
    font-size: 2vw;
    font-weight: normal;
    color: var(--primary-dark-color);
    cursor: pointer;
}

.BellIconOpened {
    padding-right: 2vw;
}

.NotificationCount {
    font-family: IRANSansFaNum, serif;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--primary-dark-color);
    color: white;
    border-radius: 50%;
    width: 1.3vw;
    height: 1.3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8vw;
    font-weight: bold;
}

.NotificationSectionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 25%;
    z-index: 1;
    background-color: rgba(231, 235, 239, 1);
}

.NotificationsText {
    font-family: IRANSans, serif;
    font-size: 1.1vw;
    font-weight: bold;
    color: var(--primary-dark-color);
    padding-right: 0.9vw;
}

.readAllButton {
    margin-right: 7vw;
    height: 2vw;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid var(--primary-light-color);
    background-color: var(--primary-light-color);
    font-family: IRANSans, serif;
    font-size: 0.9vw;
    font-weight: bold;
    color: white;
}