.NotificationSectionContainer {
    left: 0;
    top: 8vh;
    height: 100%;
    width: 100%;
    background-color: rgba(231, 235, 239, 1);
    overflow-y: auto;
    transition: left 0.5s ease;
}

@keyframes slideIn {
    from {
        left: -69%;
        /* Start position */
    }

    to {
        left: 0;
        /* End position */
    }
}

.NotificationSectionContainer.open {
    left: 0;
    animation: slideIn 0.5s forwards;
}

.NotificationSectionContent {
    border-top: 1px solid var(--grey-color);
    display: flex;
    flex-direction: column;
    padding: 2vw;
}

.NotificationItem {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    border: 1px solid var(--grey-color);
    height: 6vw;
    background-color: white;
    direction: rtl;
    margin-bottom: 0.7vw;
    align-items: center;
}

.NotificationItem.unread {
    border-right: 6px solid var(--primary-dark-color);
}

.ItemIcon {
    margin-left: 1vw;
    margin-right: 0.5vw;
    font-size: 2vw;
    color: var(--primary-light-color);
    height: 100%;
}

.ItemContent {
    display: flex;
    flex-direction: column;
    margin-right: 1vw;
}

.ItemHeader {
    font-family: IRANSans, serif;
    font-size: 0.9vw;
    direction: rtl;
    font-weight: bold;
    padding-left: 0.3vw;
    color: var(--primary-dark-color);
    padding-top: 0.5vw;
}

.ItemTime {
    font-family: IRANSans, serif;
    font-size: 0.8vw;
    direction: rtl;
    padding-top: 0.5vw;
    padding-right: 0.5vw;
    font-weight: bold;
    color: var(--grey-color);
}