.PaginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: ltr;
}

.PaginationControls {
    display: flex;
    align-items: center;
}

.PaginationButton {
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
    padding: 8px 16px;
    margin: 0 4px;
    cursor: pointer;
    color: var(--primary-light-color);
    background-color: inherit;
    border: none;
}

.PaginationButton:disabled {
    color: var(--grey-color);
    cursor: not-allowed;
}

.PageNumber {
    padding: 8px 12px;
    margin: 0 4px;
    font-weight: bold;
    font-family: IRANSansFaNum, serif;
    font-size: 1vw;
    color: var(--primary-light-color);
    cursor: pointer;
}

.PageNumber.Active {
    color: var(--grey-color);
}

.ItemCount {
    font-size: 1vw;
    color: var(--grey-color);
    font-weight: bold;
    font-family: IRANSansFaNum, serif;
    direction: rtl;
}

@media screen and (max-width: 480px) {
    .PaginationContainer {
        flex-direction: column;
        align-items: center;
    }

    .PaginationControls {
        margin-bottom: 10px;
    }

    .PaginationButton {
        font-size: 3.5vw;
        padding: 10px 18px;
    }

    .PageNumber {
        font-size: 3.5vw;
        padding: 10px 14px;
    }

    .ItemCount {
        font-size: 3.5vw;
        margin-top: 10px;
    }
}