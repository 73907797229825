.modalOverlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10000;
}

.modal {
    position: relative;
    background-color: white;
    border-radius: 5px 5px 0 0;
    /* Rounded corners at the top */
    padding: 2vw;
    width: 100%;
    height: 70%;
    transition: transform 0.3s ease-in-out;
    /* Smooth slide-in effect */
    display: flex;
    flex-direction: column;
    direction: rtl;
    align-items: center;
    z-index: 11000;
}

.show {
    opacity: 1;
    transform: translateY(0);
}

.closeIcon {
    cursor: pointer;
    font-size: 6vw;
    color: var(--black-color);
    margin-left: 4vw;
}

.InquiryHistoryContainer,
.AddCommentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.InquiryHistoryContainer {
    overflow-y: auto;
}

.HeaindgContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.AddCommentContainer {
    margin-top: 2vw;
}

.heading {
    font-size: 4.5vw;
    font-family: IRANSans, serif;
    font-weight: bold;
    color: var(--primary-dark-color);
    margin-right: 4vw;
}

.HistoryContainer {
    display: flex;
    border-radius: 10px;
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.FirstHistoryContainer {
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    width: 90%;
    height: 100%;
    align-self: center;
    overflow-y: auto;
}

.inputText {
    width: 90%;
    padding: 1vw;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    text-align: right;
    direction: rtl;
    color: black;
    background-color: white;
    font-family: IRANSans, serif;
    margin-bottom: 1vw;
    align-self: center;
}

.SendButton {
    cursor: pointer;
    background-color: var(--primary-light-color);
    text-align: center;
    font-family: IRANSans, serif;
    color: white;
    font-size: 3.3vw;
    font-weight: bold;
    border-radius: 5px;
    border: 1.5px solid var(--primary-light-color);
    margin-top: 2vw;
    margin-left: 1vw;
    width: 30%;
    height: 8vw;
    align-self: center;
}

.SendButton:disabled {
    background-color: var(--grey-color);
    color: var(--primary-dark-color);
    cursor: not-allowed;
}

.loading_container {
    display: grid;
    place-items: center;
    height: 100%;
}