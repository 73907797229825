@font-face {
    font-family: IRANSans;
    src: url("IRANSansWeb.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: IRANSans;
    src: url("IRANSansWeb_Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: IRANSansFaNum;
    src: url("IRANSansWeb(FaNum).ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: IRANSansFaNum;
    src: url("IRANSansWeb(FaNum)_Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}