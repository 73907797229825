.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageContainer {
    width: 100%;
    height: 100%;
}

.image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.controls {
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1vw;
}

.controls button {
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    background-color: white;
    border: 1px solid var(--primary-dark-color);
    border-radius: 3px;
    cursor: pointer;
    color: var(--primary-dark-color);
    font-weight: bold;
    font-family: IRANSans, serif;
    font-size: 0.9vw;
}

.controls button:hover {
    background-color: #e0e0e0;
}