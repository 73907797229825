.HomePageContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.HomePagePhoto {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 0;
    top: -120px;
}

.FooterContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: rgba(34, 38, 39, 1);
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 35vh;
    align-items: center;
    justify-content: center;
}

.Header {
    font-family: IRANSans, serif;
    font-size: 2vw;
    color: white;
}

.ButtonsContainer {
    display: flex;
    justify-content: space-between;
    direction: rtl;
    width: 22%;
}

.LoginButton,
.SignupButton {
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
    width: 10vw;
    height: 2.5vw;
    border-radius: 5px;
    cursor: pointer;
}

.LoginButton {
    background-color: var(--primary-light-color);
    color: white;
    border: 1px solid var(--primary-light-color);
}

.SignupButton {
    background-color: rgba(34, 38, 39, 1);
    color: white;
    border: 1px solid white;
}