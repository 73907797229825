.HomePageContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

/*.HomePagePhoto {*/
/*    height: 60vh;*/
/*    object-fit: cover;*/
/*    z-index: 0;*/
/*}*/

.FooterContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: rgba(34, 38, 39, 1);
    width: 100%;
    height: 100vh;
    align-items: center;
    /*justify-content: center;*/
    /*z-index: 1;*/
    /*bottom: 0;*/
}

.Header {
    font-family: IRANSans, serif;
    font-size: 6vw;
    margin-bottom: 10vw;
    color: white;
    margin-top: 200px;
}

.ButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
    width: 100%;
}

.LoginButton,
.SignupButton {
    font-family: IRANSans, serif;
    font-size: 4vw;
    font-weight: bold;
    width: 50%;
    height: 10.5vw;
    border-radius: 5px;
    cursor: pointer;
}

.LoginButton {
    background-color: var(--primary-light-color);
    color: white;
    border: 1px solid var(--primary-light-color);
}

.SignupButton {
    background-color: rgba(34, 38, 39, 1);
    color: white;
    border: 1px solid white;
    margin-top: 3vw;
}