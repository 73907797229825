.NavbarContainer {
    position: fixed;
    top: 10vh;
    right: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    direction: rtl;
}

.navElements {
    width: 55%;
    height: 100vh;
    overflow: hidden;
    transition: all 0.3s ease-in;
    background-color: var(--primary-dark-color);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navElements.active {
    width: 270px;
}

.navElements ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.navElements ul li {
    text-align: right;
    border-bottom: 2px solid rgba(0, 125, 137, 1);
    padding: 4vw 4vw;
}

.navElements ul li:last-child {
    border: none;
}

.navElements ul a {
    font-size: 4vw;
    font-weight: bold;
    color: white;
    text-decoration: none;
}

.navElements ul li:last-child a {
    color: rgba(255, 148, 148, 1);
}