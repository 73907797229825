.Navbar {
    display: flex;
    direction: rtl;
    border-bottom: 1px solid var(--grey-color);
}

.NavbarList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2vw;

    margin: 0;
    width: 95%;
    list-style: none;
}

.Navbar li {
    cursor: pointer;
    position: relative;
    padding-bottom: 1vw;

    font-family: IRANSansFaNum, serif;
    font-size: 1.1vw;
    font-weight: normal;
    color: var(--primary-dark-color);
}

.Navbar li.selected {
    font-weight: bold;
    border-bottom: 2px solid var(--primary-dark-color);
}