/*.UserInquiriesContainer {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    padding: 0 3vw 3vw 3vw;*/
/*    justify-content: center;*/
/*}*/

.UserInquiriesContainer {
    display: flex;
    flex-direction: column;
    width: 96%;
    direction: rtl;
    margin: 0 auto;
    height: 100%;
    /* centers the container horizontally */
}

.InquiriesHeading {
    direction: rtl;
    font-size: 1.2vw;
    font-weight: bold;
    color: var(--primary-dark-color);
    font-family: IRANSans, serif;
}

.NavbarContainer {
    display: flex;
    flex-direction: column;
}

.SortingAndShowingContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 1.5vw;
    width: 100%;
    height: 3vw;
    direction: rtl;
}

.SearchContainer {
    display: flex;
    flex-direction: row;
    direction: rtl;
    align-items: center;

    right: 0;
    width: 40%;
}

.SearchInput {
    width: 75%;
    height: 75%;
    font-size: 1vw;
    border-radius: 5px;
    border: 1.5px solid var(--grey-color);
    font-family: IRANSans, serif;
}

.SearchButton {
    cursor: pointer;
    background-color: var(--primary-light-color);
    text-align: center;

    font-family: IRANSans, serif;
    color: white;
    font-size: 1vw;
    font-weight: bold;

    border-radius: 5px;
    border: 1.5px solid var(--primary-light-color);
    margin-right: 2vw;
    width: 45%;
    height: 90%;
}

.SortingContainer {
    display: flex;
    direction: rtl;
    align-items: center;
    width: 25%;
}

.BodyText {
    font-family: IRANSans, serif;
    width: 25%;
    font-size: 1vw;
}

.InquiryDropdown {
    direction: rtl;
    font-family: IRANSans, serif;
    color: var(--black-color);

    border-color: var(--grey-color);
    border-radius: 10px;

    width: 75%;
    height: 100%;
    font-size: 1vw;
    padding-right: 1vw;

    background-image: url("../../../assets/images/chevron-down.jpg");
    background-position: left 3px center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.InquiryDropdown:focus {
    outline: none;
    border-color: var(--primary-dark-color);
    box-shadow: 0 0 0 2px var(--primary-dark-color);
}

.InquiryItemsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 2vw;
    flex-direction: row;
    direction: rtl;
    /*This is for adjusting items with different heights*/
    align-items: center;
}

.EmptyInquiriesContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-self: center;
    width: 100%;
}

.EmptyInquiries {
    width: 25%;
    height: 25%;
    margin-top: 1vw;
    color: var(--primary-light-color);
    margin-bottom: 1vw;
    text-align: center;
    align-self: center;
}

.EmptyInquiriesMessage {
    font-family: IRANSans, serif;
    direction: rtl;
    text-align: center;
    color: var(--primary-dark-color);
    font-weight: bold;
}

.InquiryBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 22.5%;

    margin-bottom: 15px;
    margin-left: 2vw;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
}

.ContentContainer {
    display: flex;
    flex-direction: column;
    direction: rtl;
    width: 100%;
    padding: 1vw;
    gap: 0.8vw;
    position: relative;
}

.InfoSection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.RightSection {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.FirstRow,
.SecondRow,
.ThirdRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.returnedText {
    padding: 0.1vw 0.5vw;
    background-color: var(--returned-color);
    color: white;
    border-radius: 5px;
    font-size: 0.8vw;
    font-family: IRANSans, serif;
    font-weight: bold;
    align-items: center;
    display: flex;
}

.NameText {
    font-family: IRANSans, serif;
    font-size: 1vw;
    color: var(--primary-dark-color);
    font-weight: bold;
    direction: rtl;
}

.DateText {
    font-family: IRANSans, serif;
    font-size: 0.9vw;
    color: var(--grey-color);
    font-weight: normal;
    margin-right: 0.5vw;
}

.MoreInfoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1vw 1vw 1vw;
    color: var(--primary-dark-color);
    font-weight: normal;
    font-size: 1vw;
}

.CoinIcon {
    font-size: 1.3vw;
    font-weight: lighter;
    margin-left: 0.5vw;
}

.BestPriceText {
    align-self: flex-end;

    font-family: IRANSans, serif;
    direction: rtl;
}

.CalendarIcon {
    font-size: 1.5vw;
    margin-right: 1vw;
    font-weight: bold;
    margin-left: 0.5vw;
}

.ApprovedDateText {
    font-family: IRANSans, serif;
    font-size: 0.9vw;
}

.CreateNewInquiryButton {
    cursor: pointer;
    align-self: center;
    width: 15%;
    height: 3vw;
    background-color: var(--primary-light-color);
    text-align: center;
    border-radius: 10px;
    font-family: IRANSans, serif;
    color: white;
    font-size: 1vw;
    font-weight: bold;
    border: 1.5px solid var(--primary-light-color);
    bottom: 1vw;
    position: fixed;
}

.PaginationWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ItemCount {
    font-size: 1vw;
    color: var(--grey-color);
    font-weight: bold;
    font-family: IRANSansFaNum, serif;
    direction: rtl;
}