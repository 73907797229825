.container,
.auditor_container {
    display: flex;
    flex-direction: column;
    width: 96%;
    direction: rtl;
    margin: 0 auto;
    max-height: 100%;
    /* centers the container horizontally */
}

.auditor_container {
    width: 60%;
    height: 85%;
}

.loading_container {
    display: grid;
    place-items: center;
}

.pageTitle {
    color: var(--primary-dark-color);
    margin-bottom: 24px;
    font-size: 1.2vw;
    font-weight: bold;
    font-family: IRANSans, serif;
}

.gridContainer {
    display: flex;
    grid-template-columns: 1fr;
    gap: 1vw;
    width: 100%;
    height: 100%;
}

.columnContainer,
.secondColumnContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.secondColumnContainer {
    gap: 1vw;
}

.card {
    background: white;
    border-radius: 12px;
    padding: 0.5vw 1.5vw 1vw 1.5vw;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    height: 100%;
    border: 1px solid var(--grey-color);
    display: flex;
    flex-direction: column;
}

.sectionTitle {
    color: var(--primary-dark-color);
    margin-bottom: 0.7vw;
    font-size: 1.3vw;
    font-weight: bold;
    font-family: IRANSans, serif;
}

.sectionHint {
    font-family: IRANSans, serif;
    font-size: 0.9vw;
    margin-bottom: 1.5vw;
}

/* Personal Info Styles */
.infoGrid {
    margin-top: 1vw;
    display: grid;
    gap: 2vw;
    grid-template-columns: repeat(2, 1fr);
}

.infoItem {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    padding: 0.5vw;
    border-radius: 10px;
    background-color: #f9fafb;
    transition: all 0.2s ease;
}

.infoItem:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
}

.infoItem label {
    color: var(--darkGray-color);
    font-size: 1.1vw;
    font-family: IRANSans, serif;
}

.value,
.displayValue{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 1vw;
    background-color: #fff;
    font-family: IRANSansFaNum, serif;
    word-break: break-word;
}

.displayValue {
    border: none;
    background-color: transparent;
    padding: 6px 0;
    font-size: 1.1vw;
    color: #333;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.editButton {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--primary-dark-color);
    opacity: 0.7;
    transition: opacity 0.2s ease;
    padding: 4px;
    border-radius: 50%;
}

.editButton:hover {
    opacity: 1;
    background-color: rgba(var(--primary-dark-color), 0.1);
}

.editIcon {
    font-size: 1.5vw;
    direction: ltr;
}

.verified {
    position: relative;
}

.verified::after {
    content: '';
    width: 20px;
    height: 20px;
    background-color: #10b981;
    border-radius: 50%;
    position: absolute;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

/* Performance Stats Styles */
.statsContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    justify-content: space-between;
}

.statItem {
    display: flex;
    flex-direction: column;
}

.statItem label {
    color: var(--darkGray-color);
    font-size: 1.1vw;
    margin-bottom: 8px;
    font-family: IRANSans, serif;
}

.statItem .value {
    font-size: 1.5vw;
    font-weight: 600;
    color: var(--black-color);
    border: none;
    padding: 0;
    font-family: IRANSansFaNum, serif;
    direction: rtl;
}

.viewReportsButton {
    width: 24%;
    padding: 10px;
    background-color: var(--primary-light-color);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9vw;
    font-weight: bold;
    font-family: IRANSans, serif;

    display: block; /* Ensures it behaves like a block element */
}


/* Recent Requests Styles */
.requestsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1vw;
    margin-bottom: 1.5vw;
}

.requestItemContainer {
    display: flex;
    justify-content: space-between;
    width: 90%;
    height: 70%;
    border: 1px solid var(--grey-color);
    border-radius: 8px;
    padding: 1vw;
    gap: 1vw;
    cursor: pointer;
}

.requestItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
}

.requestSubject {
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
    color: var(--primary-dark-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.secondRowContainer {
    display: flex;
    gap: 1vw;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.requestDate {
    font-family: IRANSans, serif;
    font-size: 0.9vw;
    color: var(--darkGray-color);
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    width: 80%;
    align-items: center;
    margin-top: auto;
}

.viewAllButton {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--primary-light-color);
    color: white;
    border: none;
    font-size: 0.9vw;
    font-weight: bold;
    font-family: IRANSans, serif;
    width: 30%;
}

.newRequestButton {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--primary-dark-color);
    color: white;
    border: none;
    font-size: 0.9vw;
    font-weight: bold;
    font-family: IRANSans, serif;
    width: 30%;
}

.editInputContainer {
    display: flex;
    flex-direction: column;
    gap: 0.9vw;
}

.editInputField {
    display: flex;
    gap: 0.9vw;
    align-items: center;
    width: 100%;
}

.editInput {
    font-family: IRANSans, serif;
    padding: 0.8vw;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    font-size: 1vw;
    width: 100%;
    transition: border 0.2s ease;
}

.editInput:focus {
    border-color: var(--primary-dark-color);
    outline: none;
    box-shadow: 0 0 0 2px rgba(var(--primary-dark-color), 0.2);
}

.saveButton {
    font-family: IRANSans, serif;
    padding: 0.7vw;
    background-color: var(--primary-dark-color);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1vw;
    transition: all 0.2s ease;
}

.saveButton:hover:not(:disabled) {
    background-color: #0056b3; /* Darker shade for hover */
}

.saveButton:disabled{
    background-color: var(--grey-color);
    cursor: not-allowed;
}

.EmptyInquiriesContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-self: center;
    margin-bottom: 3vw;
}

.EmptyInquiries {
    color: var(--primary-light-color);
    margin-bottom: 1vw;
    text-align: center;
    align-self: center;
    object-fit: contain;
}

.EmptyInquiriesMessage {
    font-family: IRANSans, serif;
    direction: rtl;
    text-align: center;
    color: var(--primary-dark-color);
    font-weight: bold;
    font-size: 0.9vw;
}