.performance_section_container {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 100%;
    gap: 1vw;
}

.performance_section_first_row,
.performance_section_second_row {
    display: flex;
    width: 100%;
    gap: 1vw;
}

.performance_section_first_row {
    height: 60%;
}

.performance_section_second_row {
    height: 40%;
}

.performance_section_first_row_first_col {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1vw;
}

.performance_section_first_row_first_col_first_row {
    display: flex;
    gap: 1vw;
    width: 100%;
}

.performance_section_first_row_first_col_second_row {
    width: 100%;
    height: 100%;
}

.pie_chart {
    display: flex;
    flex-direction: column;
    padding: 0.5vw 1vw;
    background-color: white;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    font-family: IRANSans, serif;
    box-sizing: border-box;
    height: 100%;
    width: 50%;
}

.pie_chart_body {
    align-self: center;
    width: 100%;
    height: 80%;
    box-sizing: border-box;
}

.pie_chart_body canvas {
    max-width: 100%;
    max-height: 100%;
}