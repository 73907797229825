.inquiryUpdates,
.inquiryUpdatesTable {
    display: flex;
    background-color: indianred;
    border-radius: 5px;
    width: 2vw;
    align-items: center;
    justify-content: space-between;
    padding: 0.4vw;
}

.inquiryUpdates {
    position: absolute;
    bottom: 1vw;
    left: 1vw;
}

.bellIcon {
    font-size: 1.2vw;
    color: white;
    transform: rotate(45deg);
}

.updateCount {
    font-family: IRANSansFaNum, serif;
    color: white;
    font-size: 0.8vw;
    font-weight: bold;
}

@media (max-width: 480px) {
    .inquiryUpdates {
        width: 8vw;
        height: 5vw;
        position: relative;
        left: 1vw;
        padding: 2vw;
    }

    .bellIcon {
        font-size: 4vw;
    }

    .updateCount {
        font-size: 4vw;
    }
}