/*FIXME: duplicate with inquiriesContainer in UserInquiriesModule.module.css*/
/*.newInquiryContainer {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    padding: 0 1vw 0 3vw;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.newInquiryContainer {
    display: flex;
    flex-direction: column;
    width: 96%;
    direction: rtl;
    margin: 0 auto;
    height: 100%;
    /* centers the container horizontally */
}


.headingContent {
    display: flex;
    align-items: center;
    margin-left: auto;
}

/*FIXME: duplicate with inquiriesHeading in UserInquiriesModule.module.css*/
.inquiriesHeading {
    margin-left: auto;
    font-size: 1.2vw;
    font-weight: bold;
    color: var(--primary-dark-color);
    font-family: IRANSans, serif;
}

.goBackIcon {
    margin-left: 1vw;
    color: var(--primary-dark-color);
    cursor: pointer;
}

.NameContainer {
    display: flex;
    direction: rtl;
    gap: 1vw;
}

.inquiryHeading {
    font-weight: bold;
    color: var(--primary-dark-color);
    direction: rtl;
    font-family: IRANSans, serif;
    font-size: 1.1vw;
}

.newInquiryPageContainer {
    display: flex;
    direction: rtl;
    width: 90%;
    gap: 1vw;
    margin-top: 2vw;
    height: 80vh;
}

.newInquiry {
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-left: auto;
    align-items: center;
    height: 100%;
}

.Field {
    font-family: IRANSans, serif;
    font-size: 1.1vw;
    font-weight: bold;
}

.Explanation {
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: normal;
    color: var(--darkGray-color);
    margin-top: 0.5vw;
}

.CommentTextbox,
.SubjectTextbox {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 10vw;
    margin-top: 1vw;
    padding: 1vw;
    direction: rtl;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    margin-bottom: 1vw;
    background-color: white;
    overflow-y: auto;
}


.inputText,
.subjectInputText {
    width: 90%;
    padding: 0.7vw;
    padding-right: 0.5vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    margin-left: auto;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    text-align: right;
    direction: rtl;
    min-height: 3vw;
    color: black;
    background-color: white;
    font-family: IRANSans, serif;
    font-size: 1vw;
}

.subjectInputText {
    min-height: 1vw;
    width: 60%;
}

.characterLimit {
    font-family: IRANSansFaNum, serif;
    font-size: 0.9vw;
}

.inputText:disabled {
    cursor: not-allowed;
}

.inputTextError {
    border-color: var(--red-color);
}

.newInquiryFileUpload,
.newInquiryFileUploadNew {
    display: flex;
    flex-direction: column;
    direction: rtl;
    width: 90%;
    /* height: 70%; */
    border: 0.5px solid var(--grey-color);
    border-radius: 10px;
    padding: 1vw;
    margin-bottom: 1vw;
    background-color: white;
    overflow-y: auto;
}

.uploadBox {
    border: 1px dashed var(--primary-light-color);
    padding-top: 5vw;
    padding-bottom: 5vw;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 1vw;
    margin-bottom: 1vw;
    margin-left: auto;
    cursor: pointer;
    width: 100%;
}

.uploadBox:disabled {
    cursor: not-allowed;
}

.uploadBoxError {
    border-color: var(--red-color);
}

.uploadBoxNoEdit {
    border-color: var(--grey-color);
}

.uploadInput {
    display: none;
}

.cloudIcon {
    color: var(--primary-dark-color);
    font-size: 1.5vw;
    margin-bottom: 0.5vw;
}

.uploadText {
    font-size: 1vw;
    font-family: IRANSans, serif;
    color: var(--primary-light-color);
    text-align: center;
    font-weight: bold;
}

.uploadBoxNoEdit * {
    color: var(--grey-color);
    /* Apply grey color to all child elements */
}

.imageList {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 1vw;
}

.uploadedImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 0.5px solid var(--grey-color);
    border-radius: 10px;
    height: 100%;
    margin-bottom: 1vw;
    position: relative;
    direction: rtl;
}

.uploadedImage,
.uploadedPdf {
    width: 85%;
    height: 10vw;
    object-fit: cover;

    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    position: relative;
    cursor: pointer;
}

.uploadedPdf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.pdfIcon {
    font-size: 5vw;
    align-self: center;
    color: var(--red-color);
}

.pdfText {
    font-size: 1vw;
    color: #333;
    font-family: IRANSans, serif;
}

.myChoiceText,
.auditorChoiceText {
    position: absolute;
    margin-top: 0.7vw;
    padding: 0.5vw 1.2vw;
    color: white;
    border-radius: 5px;
    z-index: 1;
    font-size: 0.7vw;
    font-family: IRANSans, serif;
    font-weight: bold;
    align-items: center;
    display: flex;
}

.myChoiceText {
    background-color: var(--client-choice-color);
    right: 0.7vw;
}

.auditorChoiceText {
    background-color: var(--auditor-choice-color);
    left: 6vw;
}

.imageActions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15%;

    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.deleteButton,
.starButton {
    border: none;
    height: 50%;
    font-size: 1vw;
    cursor: pointer;
}

.deleteButton {
    background-color: var(--red-color);
    border-bottom-left-radius: 10px;
}

.starButton:disabled,
.deleteButton:disabled {
    background-color: var(--grey-color);
    cursor: not-allowed;
}

.starButton {
    background-color: var(--primary-light-color);
    border-top-left-radius: 10px;
}

.imageTable {
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    width: 80%;
    align-self: center;
    margin-top: 2vw;
}

.customRadio {
    -webkit-appearance: none;
    appearance: none;
    width: 1.2vw;
    height: 1.2vw;
    border: 2px solid var(--primary-dark-color);
    border-radius: 50%;
    outline: none;
    transition: 0.2s all linear;
    position: relative;
    top: 2px;
    cursor: pointer;
}

.customRadio:checked {
    border: 6px solid var(--primary-light-color);
    background: var(--primary-light-color);
}

.customRadio:disabled {
    border-color: #ccc;
    background-color: #ddd;
    cursor: not-allowed;
}

.customRadio:checked:disabled {
    border-color: var(--primary-dark-color);
    background-color: var(--primary-dark-color);
}

.Icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2vw;
}

.EyeIcon {
    color: var(--primary-dark-color);
    font-size: 1.3vw;
}

.DownloadIcon {
    color: var(--primary-dark-color);
    font-size: 1.3vw;
}

.TrashIcon {
    color: var(--red-color);
    font-size: 1.2vw;
}

.enlargedImageContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.enlargedImage {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;

    border-radius: 10px;
}

.newCommentLink {
    direction: rtl;
    margin-top: 1vw;
    font-family: IRANSans, serif;
    color: var(--primary-light-color);
    font-weight: bolder;
    font-size: 1vw;
    cursor: pointer;
}

.removeNewCommentLink {
    color: var(--red-color);
}

.sendOrDiscardButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    direction: rtl;
    margin-top: 2vw;
}

.sendButton,
.discardButton {
    bottom: 0;
    left: 0;
    cursor: pointer;
    height: 3vw;
}

.sendButton,
.AddButton {
    width: 30%;
    margin-left: 5%;
    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    background-color: var(--primary-light-color);
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
    color: white;
}

.sendButton:disabled,
.AddButton:disabled {
    background-color: var(--grey-color);
    border: 1.5px solid var(--grey-color);
    cursor: not-allowed;
}

.AddButton {
    margin-top: 1vw;
    align-self: center;
    cursor: pointer;
}

.discardButton {
    width: 15%;
    border-radius: 10px;
    border: 1.5px solid var(--red-color);
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
    color: var(--red-color);
    background-color: white;
}


.ThirdColumn {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    width: 35%;
    /* height: 100%; */
}

.InquiryHistoryTimeLine,
.AddingCommentContainer {
    display: flex;
    flex-direction: column;
    border: 0.5px solid var(--grey-color);
    border-radius: 10px;
    margin-left: 1vw;
    background-color: white;
    padding: 1vw;
    overflow-y: auto;
}

.InquiryHistoryTimeLine {
    height: 50%;
}

.AddingCommentContainer {
    height: 30%;
    gap: 1vw;
}

.AuditorInquiryPageHeader {
    font-family: IRANSans, serif;
    font-size: 1.1vw;
    font-weight: bold;
    color: var(--primary-dark-color);
}

.SendButton {
    cursor: pointer;
    background-color: var(--primary-light-color);
    text-align: center;
    font-family: IRANSans, serif;
    color: white;
    font-size: 1vw;
    font-weight: bold;
    border-radius: 5px;
    border: 1.5px solid var(--primary-light-color);
    align-self: center;
    width: 90%;

    margin-bottom: 1vw;
    height: 2.5vw;
}

.SendButton:disabled {
    background-color: var(--grey-color);
    color: var(--primary-dark-color);
    cursor: not-allowed;
}

.commentInputField {
    width: 85%;
    align-self: center;
    height: 100%;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    text-align: right;
    direction: rtl;
    color: black;
    font-family: IRANSans, serif;
    margin-bottom: 1vw;
    font-size: 0.9vw;
    padding: 0.5vw;
}

.ReturnedCommentContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--returned-color);
    background-color: #FFE3E4;
    border-radius: 10px;
    direction: rtl;
    width: 90%;
    height: 15%;
    padding: 1vw;
    margin-bottom: 1vw;
    overflow-y: auto;
    gap: 1vw;
}

.ReturnedHeading {
    font-size: 1.1vw;
    font-weight: bold;
    color: var(--returned-color);
    font-family: IRANSans, serif;
}

.returnedComment {
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
}