/* Mobile Profile.module.css */

.container {
    display: flex;
    flex-direction: column;
    width: 94%;
    direction: rtl;
    margin: 0 auto;
    padding-bottom: 24px;
}

.loading_container {
    display: grid;
    place-items: center;
    padding: 24px 0;
}

.pageTitle {
    color: var(--primary-dark-color);
    font-size: 5vw;
    font-weight: 700;
    font-family: IRANSans, serif;
}

.card {
    border-radius: 12px;
    margin-bottom: 20px;
}

.sectionTitle {
    color: var(--primary-dark-color);
    margin-bottom: 14px;
    font-size: 18px;
    font-weight: 600;
    font-family: IRANSans, serif;
}

.sectionHint {
    font-family: IRANSans, serif;
    font-size: 13px;
    margin-bottom: 18px;
    display: block;
    line-height: 1.5;
    color: var(--darkGray-color);
}

.sectionHint .editButton {
    display: inline-flex;
    vertical-align: middle;
    font-size: 16px;
    position: relative;
    top: -1px;
}

/* Personal Info Styles */
.infoGrid {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 20px;
}

.infoItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
}

.infoItem:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.infoItem label {
    color: var(--darkGray-color);
    font-size: 14px;
    font-family: IRANSans, serif;
}

.value,
.displayValue {
    font-size: 15px;
    font-family: IRANSansFaNum, serif;
    word-break: break-word;
    color: #333;
}

.displayValue {
    padding: 4px 0;
    font-weight: 500;
}

.label_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editButton {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--primary-dark-color);
    font-size: 20px;
    padding: 4px;
}

/* Edit mode styles */
.editInputContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.editInputField {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.editInput {
    font-family: IRANSans, serif;
    padding: 12px 14px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 15px;
    width: 80%;
}

.editInput:focus {
    outline: none;
    border-color: var(--primary-dark-color);
    box-shadow: 0 0 0 2px rgba(var(--primary-dark-color), 0.2);
}

.saveButton {
    font-family: IRANSans, serif;
    padding: 12px;
    background-color: var(--primary-dark-color);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    width: 20%;
    /*margin-top: 4px;*/
    transition: background-color 0.2s;
}

.saveButton:active {
    background-color: var(--primary-dark-color, #0056b3);
    transform: translateY(1px);
}

.saveButton:disabled{
    background-color: var(--grey-color);
    cursor: not-allowed;
}

/* Add styles for recent inquiries section on mobile */
.requestItemContainer {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.requestItemContainer:active {
    transform: scale(0.98);
}

.requestItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.secondRowContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.requestSubject {
    font-family: IRANSans, serif;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.requestDate {
    font-family: IRANSansFaNum, serif;
    font-size: 12px;
    color: #666;
}

.buttonGroup {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 16px;
}

.viewAllButton, .newRequestButton, .viewReportsButton {
    font-family: IRANSans, serif;
    padding: 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

.newRequestButton {
    background-color: var(--primary-dark-color);
    color: white;
    border: none;
}

.viewAllButton, .viewReportsButton {
    background-color: transparent;
    color: var(--primary-dark-color);
    border: 1px solid var(--primary-dark-color);
}