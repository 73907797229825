.progressContainer {
    width: 100%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
    font-family: IRANSansFaNum, serif;
}

.progressBar {
    height: 100%;
    background-color: var(--primary-light-color);
    text-align: center;
    color: white;
    line-height: 20px;
    transition: width 0.2s ease;
    border-radius: 8px;
}
