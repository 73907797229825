.UserInquiriesContainer {
    display: flex;
    justify-content: space-between;
    direction: rtl;
    flex-direction: column;
    margin: 0 5vw 1vw 5vw;
}

.InquiriesStatusesContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    direction: rtl;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
}

.InquiriesHeading {
    margin-bottom: 3vw;
    margin-top: 2vw;
    font-size: 4vw;
    font-weight: bold;
    color: var(--primary-dark-color);
    font-family: IRANSans, serif;
}

.InquiryDropdown {
    direction: rtl;
    font-family: IRANSansFaNum, serif;
    color: var(--black-color);
    background-color: white;

    border-color: var(--grey-color);
    border-radius: 10px;

    width: 100%;
    height: 100%;
    padding: 2.5vw 3vw 2.5vw 3vw;
    font-size: 3.5vw;

    background-image: url("../../../assets/images/chevron-down.jpg");
    background-position: left 10px center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.InquiryDropdown:focus {
    outline: none;
    border-color: var(--primary-dark-color);
    box-shadow: 0 0 0 2px var(--primary-dark-color);
}

.SortingAndShowingContainer {
    display: flex;
    flex-direction: column;
    margin-top: 5vw;
    width: 100%;
    z-index: 1;
}

.SortingContainer {
    display: flex;
    direction: rtl;
    justify-content: space-between;
    align-items: center;
}

.BodyText {
    font-family: IRANSans, serif;
    width: 33%;
    font-size: 3.5vw;
    font-weight: bold;
}

.InquiryItemsContainer {
    display: flex;
    width: 100%;
    padding-top: 7.5vw;
    /*padding-bottom: 20vw;*/
    flex-direction: column;
}

.EmptyInquiriesContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.EmptyInquiries {
    width: 65%;
    height: 65%;
    margin-top: 10%;
    color: var(--primary-light-color);
    margin-bottom: 10%;
}

.EmptyInquiriesMessage {
    font-family: IRANSans, serif;
    direction: rtl;
    color: var(--primary-dark-color);
    font-size: 3.5vw;
    text-align: center;
    font-weight: bold;
}

.InquiryBox {
    display: flex;
    direction: rtl;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    background-color: white;
}

.ArrowContainer {
    margin-left: 10px;
    margin-top: 5px;
}

.ArrowIcon {
    font-size: 6vw;
    font-weight: normal;
    color: var(--primary-dark-color);
}

.ContentContainer {
    display: flex;
    flex-direction: column;
    direction: rtl;
    width: 100%;
    padding: 2vw;
    gap: 0.8vw;
    position: relative;
}

.NameText {
    align-self: flex-end;
    margin: 3vw 3vw 1vw auto;

    font-family: IRANSans, serif;
    font-size: 4vw;
    color: var(--primary-dark-color);
    font-weight: bold;
}

.DateText {
    align-self: flex-end;
    margin: 1vw 3vw 1vw auto;
    font-size: 3.5vw;
    font-family: IRANSans, serif;
    color: var(--grey-color);
    font-weight: normal;
}

.MoreInfoContainer {
    display: flex;
    align-items: center;
    margin: 1vw 3vw 1vw auto;
    color: var(--primary-dark-color);
    font-weight: normal;
}

.CoinIcon {
    font-size: 5vw;
    margin-left: 2vw;
    font-weight: lighter;
}

.BestPriceText {
    align-self: flex-end;

    font-family: IRANSans, serif;
    direction: rtl;
    margin-left: 1.5vw;
}

.CalendarIcon {
    font-size: 5vw;
    margin-right: 9vw;
    margin-left: 2vw;
    font-weight: bold;
}

.ApprovedDateText {
    font-family: IRANSans, serif;
    font-size: 3.5vw;
}

/* .CreateNewInquiryButtonContainer {
    background-color: white;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10%;
    bottom: 0;
    direction: rtl;
    right: 0;
} */

.CreateNewInquiryButton {
    align-self: center;
    text-decoration: none;
    color: white;
    font-family: IRANSans, serif;
    font-weight: bold;
    font-size: 4.5vw;

    position: fixed;
    bottom: 5vw;
    width: 90%;
    background-color: var(--primary-light-color);
    border: 1.5px solid var(--primary-light-color);
    text-align: center;
    border-radius: 10px;
    padding-top: 3vw;
    padding-bottom: 3vw;
}

.InfoSection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.RightSection {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.FirstRow,
.SecondRow,
.ThirdRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.returnedText {
    padding: 1vw 1.5vw;
    background-color: var(--returned-color);
    color: white;
    border-radius: 5px;
    font-size: 3.5vw;
    font-family: IRANSans, serif;
    font-weight: bold;
    align-items: center;
    display: flex;
}

.PaginationWrapper {
    direction: rtl;
    padding-top: 5vw;
    padding-bottom: 20vw;
    display: flex;
    align-items: center;
    align-self: center;
}