.modalOverlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}

.modal {
    position: relative;
    background-color: white;
    border-radius: 8px;
    padding: 2vw;
    width: 60%;
    height: 70%;
    overflow-y: auto;
    z-index: 100;
    display: flex;
    flex-direction: row;
    direction: rtl;
    align-items: center;
}

.show {
    opacity: 1;
    z-index: 10000;
}

.closeIcon {
    position: absolute;
    cursor: pointer;
    font-size: 2vw;
    color: var(--black-color);
    align-self: flex-start;
}

.heading {
    text-align: center;
    font-family: IRANSans, serif;
    color: var(--primary-dark-color);
    font-size: 1.1vw;
}

.FieldContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Field {
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: center;
}

.Subheading,
.AttributesHeading {
    font-family: IRANSans, serif;
    color: var(--darkGray-color);
    font-size: 1vw;
    width: 25%;
    font-weight: bold;
}

.AttributesHeading {
    margin-top: 1vw;
    width: 100%;
}

.ProductInfo {
    width: 45%;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

.process-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    margin: 50px auto;
}

.stage {
    position: relative;
    text-align: center;
}

.circle {
    width: 25px;
    height: 25px;
    background-color: #3498db;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
}

.line {
    height: 2px;
    background-color: #3498db;
    flex: 1;
}

.ChooseContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1vw;
}

.ChooseProductModalDropdown,
.ChooseProductModalDropdownAttributeKey,
.ChooseProductModalDropdownAttributeValue {
    direction: rtl;
    font-family: IRANSans, serif;
    color: var(--grey-color);
    border-color: var(--grey-color);
    border-radius: 10px;
    width: 75%;
    height: 100%;
    font-size: 0.9vw;
    position: relative;
    min-height: max-content;
}

.ChooseProductModalDropdownAttributeKey {
    width: 60%;
    margin-left: 0.5vw;
}

.ChooseProductModalDropdownAttributeValue {
    width: 40%;
}

.AddNewAttributeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.NewAttributeContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.5vw;
    gap: 0.5vw;
}

.InputField {
    width: 35%;
    margin-bottom: 1vw;
    border-radius: 10px;
    border: 1.5px solid var(--grey-color);
    font-family: IRANSans, serif;
    font-size: 0.9vw;
    padding-right: 1vw;
    margin-left: 1vw;
}

.RemoveButton {
    cursor: pointer;
    background-color: white;
    text-align: center;

    font-family: IRANSans, serif;
    color: var(--red-color);
    font-size: 0.7vw;
    font-weight: bold;

    border-radius: 10px;
    border: 1px solid var(--red-color);
    margin-right: 0.5vw;
    width: 10%;
}

.AddNewAttributeButton {
    cursor: pointer;
    background-color: white;
    text-align: center;

    font-family: IRANSans, serif;
    color: var(--primary-light-color);
    font-size: 1vw;
    font-weight: bold;

    border-radius: 10px;
    border: 1.5px dashed var(--primary-light-color);
    margin: 0.7vw;
}

.Separator {
    margin-top: 1vw;
    display: flex;
    width: 100%;
    justify-content: center;
    color: black;
}

.LineSeparator {
    width: 50%;
    margin-top: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
    height: 1px;
    background-color: var(--grey-color);
}

.FullLineSeparator {
    width: 100%;
    margin-top: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
    height: 1px;
    background-color: var(--grey-color);
}

.SearchContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    /*margin-top: 1vw;*/
    width: 100%;
    gap: 1vw;
}

.SearchSelect {
    direction: rtl;
    font-family: IRANSans, serif;
    color: var(--grey-color);
    border-color: var(--grey-color);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    font-size: 0.9vw;
    position: relative;
}

.SearchProductsContainer {
    display: flex;
    flex-direction: column;
}

.SearchInput {
    width: 98%;
    height: 2vw;
    border-radius: 10px;
    border: 1.5px solid var(--grey-color);
    font-family: IRANSans, serif;
    margin-bottom: 1vw;
}

.ChooseProductModalHeader {
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
    color: var(--primary-dark-color);
    padding-bottom: 1.5vw;
    width: 100%;
}

.RecentSelectionsContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    margin-top: 2vw;
    width: 60%;
}

.SearchedProductsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* Allow products to wrap to next line */
    gap: 0.5vw;
    width: 100%;
}

.ProductBox {
    cursor: pointer;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    padding: 0.5vw;
    margin-bottom: 0.5vw;
    font-size: 1vw;
    font-family: IRANSans, serif;
}

.ApproveButton {
    cursor: pointer;
    background-color: white;
    text-align: center;
    align-self: center;

    font-family: IRANSans, serif;
    color: var(--primary-light-color);
    font-size: 1vw;
    font-weight: bold;

    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    height: 2.5vw;
    margin-top: 1.5vw;
}

.ApproveButton {
    width: 35%;
    background-color: var(--primary-light-color);
    color: white;
}

.Receipt {
    width: 50%;
    max-height: 100%;
    margin-top: 7.5vw;
}

.ReceiptPhoto {
    width: 100%;
    cursor: pointer;
}

.uploadedPdf {
    width: 85%;
    height: 10vw;
    object-fit: cover;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}


.pdfIcon {
    font-size: 5vw;
    align-self: center;
    color: var(--red-color);
}

.pdfText {
    font-size: 1vw;
    color: #333;
    font-family: IRANSans, serif;
}

.enlargedImageContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.enlargedImage {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    cursor: pointer;

    border-radius: 10px;
}

.errorField {
    border-radius: 5px;
    box-shadow: 0 0 5px red;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(-10px);
    }

    40% {
        transform: translateX(10px);
    }

    60% {
        transform: translateX(-10px);
    }

    80% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 0.5s ease-in-out;
}