.timerContainer {
    text-align: center;
    margin-top: 10px;
    font-family: IRANSansFaNum, sans-serif;
}

.timerText {
    font-size: 14px;
    color: black;
    direction: rtl;
}

.resendText {
    font-size: 14px;
    color: #444;
    direction: rtl;
    cursor: pointer;
}

.resendLink {
    font-weight: bold;
    color: var(--primary-light-color);
    text-decoration: underline;
    cursor: pointer;
}

.resendLink:hover {
    color: var(--primary-light-color);
}