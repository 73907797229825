.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000; /* Ensure it overlays other content */
    display: flex;
    align-items: center;
    justify-content: center;
}

.messageContainer {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    text-align: center;
    max-width: 80%;
}

.message {
    font-size: 1.2rem;
    color: var(--primary-dark-color);
    margin: 0;
    font-family: IRANSans, serif;
    direction: rtl;
}
