.entity_container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.section_header_container {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    margin-bottom: 1vw;
}

.section_icon {
    color: var(--primary-dark-color);
}

.section_header {
    font-family: IRANSansFaNum, serif;
    font-weight: bold;
    color: var(--darkGray-color);
    font-size: 1.3vw;
}

.table_container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.search_or_new_container {
    display: flex;
    height: 3vw;
    align-items: center;
}

.search_container {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 1vw;
}

.search_input {
    width: 20%;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    font-family: IRANSans, serif;
    padding-right: 0.5vw;
    font-size: 1vw;
}

.search_button,
.new_button {
    cursor: pointer;
    background-color: var(--primary-light-color);
    text-align: center;
    font-family: IRANSans, serif;
    color: white;
    font-size: 1vw;
    font-weight: bold;
    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    width: 10%;
    height: 100%;
}

.new_button {
    background-color: var(--primary-dark-color);
    border: 1.5px solid var(--primary-dark-color);
    width: 15%;
}

.PaginationWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    direction: ltr;
}

.ItemCount {
    font-size: 1vw;
    color: var(--grey-color);
    font-weight: bold;
    font-family: IRANSansFaNum, serif;
    direction: rtl;
}

.EntityTableContainer {
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    overflow-x: auto;
    margin-top: 1vw;
    right: 0;
}
