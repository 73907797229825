/*FIXME: duplicate with inquiriesContainer in UserInquiriesModule.module.css*/
.inquiryContainer {
    display: flex;
    flex-direction: column;
    padding: 0 3vw 1vw;
}

.headingContent {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4vw;
    width: 100%;
}

.NameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}

/*FIXME: duplicate with inquiriesHeading in UserInquiriesModule.module.css*/
.inquiryHeading {
    font-weight: bold;
    color: var(--primary-dark-color);
    direction: rtl;
    font-family: IRANSans, serif;
    font-size: 4vw;
}

.goBackIcon {
    color: var(--primary-dark-color);
}

.CommentIcon {
    font-size: 6vw;
    color: var(--primary-dark-color);
}

.InfoContainer {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    right: auto;
    justify-content: space-between;
    width: 100%;
    direction: rtl;
}

.Field {
    display: flex;
    gap: 2vw;
    direction: rtl;
    left: 0;
    right: auto;
    width: 100%;
}

.FieldText {
    font-family: IRANSans, serif;
    font-size: 3.5vw;
    direction: rtl;
    color: var(--darkGray-color);
}

.categoryAndDate {
    font-weight: bold;
    color: var(--primary-dark-color);

    font-family: IRANSans, serif;
    font-size: 3.5vw;
    direction: rtl;
}

.table_box,
.table_box_extended {
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    overflow-x: auto;
    margin-top: 5vw;
    margin-bottom: 10vw;
    align-self: center;
    width: 95%;
    font-size: 3.5vw;
}

.table_box_extended {
    margin-bottom: 30vw;
}

.expansionRowContainer {
    display: flex;
    flex-direction: column;
}

.expansionContent {
    display: flex;
    flex-direction: column;

    font-family: IRANSans, serif;
    direction: rtl;

    padding-right: 5vw;
    padding-left: 5vw;
}

.expansionHeader {
    width: 100%;
}

.arrowUpDown {
    font-size: 4vw;
    color: var(--primary-dark-color);
}

.ChoicesContainer {
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.myChoiceText,
.auditorChoiceText {
    padding: 0.6vw 1.1vw;
    background-color: var(--client-choice-color);
    color: white;
    border-radius: 5px;
    z-index: 1;

    font-size: 2vw;
    font-family: IRANSans, serif;
    font-weight: bold;

    align-items: center;
    display: flex;
}

.auditorChoiceText {
    background-color: var(--auditor-choice-color);
}

.expansionHeaderRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.expansionLabel {
    color: var(--primary-dark-color);
    font-weight: bold;
    text-align: center;
}

.expansionBody {
    width: 100%;
}

.expansionBodyRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.expansionValue {
    color: var(--grey-color);
    font-weight: normal;
    text-align: center;
}

.showReceiptButton {
    background-color: var(--primary-light-color);
    color: white;
    border: 1px solid var(--primary-light-color);
    text-align: center;

    border-radius: 10px;
    padding-top: 2vw;
    padding-bottom: 2vw;

    margin: 2vw 5vw 5vw;
    font-family: IRANSans, serif;
    font-weight: bold;
    font-size: 3.5vw;
}

.enlargedImageContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.enlargedImage {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    cursor: pointer;

    border-radius: 10px;
}

.Explanation {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 1vw;
}

.inputText {
    width: 100%;
    height: 100%;
    margin-top: 1vw;
    padding: 1vw;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    text-align: right;
    direction: rtl;

    color: black;
    background-color: white;

    font-family: IRANSans, serif;
    margin-bottom: 30vw;
}

.finalizeOrEditInquiryButtons {
    display: flex;
    bottom: 3vw;
    width: 90%;
    height: 12vw;
    justify-content: space-between;
    direction: rtl;
    position: fixed;
    backdrop-filter: blur(5px);
    align-items: center;
    align-self: center;
}

.editButton,
.finalizeButton,
.ReceiveApprovalButton {
    position: absolute;
    height: 100%;
}

.finalizeButton {
    right: 0;
    /* Align edit button to the left */
    width: 48%;
    height: 100%;
    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    background-color: var(--primary-light-color);
    font-family: IRANSans, serif;
    font-size: 4vw;
    font-weight: bold;
    color: white;
}

.ReceiveApprovalButton {
    height: 100%;
    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    background-color: var(--primary-light-color);
    font-family: IRANSans, serif;
    font-size: 4vw;
    font-weight: bold;
    color: white;
    align-self: center;
}

.editButton {
    left: 0;
    /* Align finalize button to the right */
    width: 48%;
    height: 100%;
    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    font-family: IRANSans, serif;
    font-size: 4vw;
    font-weight: bold;
    color: var(--primary-light-color);
    background-color: white;
}

.editButtonWhole {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    font-family: IRANSans, serif;
    font-size: 4vw;
    font-weight: bold;
    color: var(--primary-light-color);
    background-color: white;
}

.editButtonWhole:disabled {
    background-color: var(--grey-color);
    border: 1.5px solid var(--grey-color);
    cursor: not-allowed;
}

.ApprovalReceiptContainer {
    display: flex;
    bottom: 0;
    width: 100%;
    height: 12vw;
    position: fixed;
    backdrop-filter: blur(5px);
    align-items: center;
    align-self: center;
    justify-content: center;
}

.Icons {
    display: flex;
    gap: 1.5vw;
}

.EyeIcon,
.DownloadIcon {
    color: var(--primary-dark-color);
    font-size: 4.5vw;
}

.EmptyTableContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10vw;
}

.EmptyTableImage {
    height: 50vw;
    width: 40vw;
    margin-bottom: 2vw;
}

.ExplanationText {
    font-family: IRANSans, serif;
    font-size: 4vw;
    font-weight: normal;
    margin-bottom: 0.5vw;
    color: var(--darkGray-color);
    align-self: center;
    direction: rtl;
}

.loading_container {
    margin-top: 100px;
    display: grid;
    place-items: center;
    height: 100%;
}

.downloadOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}