.newInquiryFileUpload {
    height: 100%;
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 2vw;
}

.heading {
    font-family: IRANSans, serif;
    color: var(--primary-dark-color);
    font-size: 1.1vw;
    border-bottom: 1px solid var(--grey-color);
    width: 90%;
    padding-bottom: 1vw;
}

.uploadBox {
    border: 1px dashed var(--primary-light-color);
    padding-top: 5vw;
    padding-bottom: 5vw;
    height: 75%;
    width: 90%;
    align-self: center;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 3vw;
    margin-bottom: 3vw;
}

.uploadBox:disabled {
    cursor: not-allowed;
}

.uploadBoxError {
    border-color: var(--red-color);
}

.uploadBoxNoEdit {
    border-color: var(--grey-color);
}

.uploadInput {
    display: none;
}

.cloudIcon {
    color: var(--primary-dark-color);
    font-size: 2.5vw;
    margin-bottom: 0.5vw;
}

.uploadText {
    font-size: 1.2vw;
    font-family: IRANSans, serif;
    color: var(--primary-light-color);
    text-align: center;
    font-weight: bold;
}

.uploadBoxNoEdit * {
    color: var(--grey-color);
    /* Apply grey color to all child elements */
}