.pdfPreviewModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.pdfPreviewContent {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pdfPreviewHeader {
    display: flex;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
    direction: rtl;
    justify-content: space-between;
}

.pdfPreviewTitle {
    font-size: 1.3vw;
    color: var(--darkGray-color);
    font-family: IRANSans, serif;
}

.closePdfPreview {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5vw;
    color: var(--black-color);
}

.pdfPreviewFooter {
    display: flex;
    padding: 15px 20px;
    border-top: 1px solid #e0e0e0;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.downloadPdf {
    background-color: var(--primary-light-color);
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: IRANSans, serif;
    font-weight: bold;
}

.pdfText {
    font-size: 0.9vw;
    color: #333;
    font-family: IRANSans, serif;
}