.financial_section_container {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 100%;
    gap: 1vw;
}

.financial_section_row_container {
    display: flex;
    gap: 1vw;
    width: 100%;
    height: 60%;
}

.financial_section_first_col,
.financial_section_second_col {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1vw;
}

.financial_section_first_col {
    width: 75%;
    height: 100%;
}

.financial_section_second_col {
    width: 25%;
    height: 100%;
}

.financial_section_first_row,
.financial_section_second_row,
.financial_section_third_row {
    display: flex;
    gap: 1vw;
    width: 100%;
}

.financial_section_first_row {
    height: 25%;
}

.financial_section_second_row {
    height: 85%;
}

.financial_section_third_row {
    height: 40%;
}

.pie_chart {
    display: flex;
    flex-direction: column;
    padding: 0.5vw 1vw;
    background-color: white;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    font-family: IRANSans, serif;
    height: 100%;
}