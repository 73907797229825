.loadingContainerMobile {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--primary-dark-color);
    border-radius: 10px;
    padding: 4vw;
    backdrop-filter: blur(5px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loadingTextMobile {
    color: white;
    font-size: 4vw;
    direction: rtl;
    font-family: IRANSans, serif;
    text-align: center;
}

.loadingContainerDesktop {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--primary-dark-color);
    border-radius: 10px;
    padding: 1vw;
    backdrop-filter: blur(5px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loadingTextDesktop {
    color: white;
    font-size: 1vw;
    direction: rtl;
    font-family: IRANSansFaNum, serif;
    text-align: center;
}

.loadingContainerNotificationDesktop {
    position: fixed;
    top: 50%;
    left: 7.5%;
    transform: translate(7.5%, -50%);
    background-color: var(--primary-dark-color);
    border-radius: 10px;
    padding: 1vw;
    backdrop-filter: blur(5px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loadingTextNotificationDesktop {
    color: white;
    font-size: 1vw;
    direction: rtl;
    font-family: IRANSansFaNum, serif;
}