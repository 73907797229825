@page {
    size: A4;
}

@media print {
    .productsTable {
        print-color-adjust: exact;
    }
}

.page {
    display: flex;
    flex-direction: column;
    font-family: 'IRANSansFaNum';
    margin-left: auto;
    margin-right: auto;
    background: white;
    position: relative;
    width: 210mm;
    height: 297mm;
    padding: 32px;
}

.header {
    display: flex;
    flex-direction: row;
}

.subheader {
    font-weight: bold;
    color: #9ca3af;
    margin-top: 1rem;
}

.websiteTitle {
    display: flex;
    flex-basis: 50%;
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 700;
}

.inquiryTitle {
    font-family: IRANSans, serif;
    display: flex;
    flex-basis: 50%;
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
    font-weight: 700;
    justify-content: flex-end;
}

.HorizontalLine {
    margin-top: 0.5rem /* 8px */;
    margin-bottom: 0.5rem /* 8px */;
    border-color: #9ca3af;
    width: 100%;
}

.dataEntry {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    font-weight: 400;
    padding: 0.25rem;

}

.dataField {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    font-weight: 700;
    padding: 0.25rem;
}

.thead {
    background-color: #eee;
}

.detailsTable {
    text-align: right;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    border-spacing: 0;
    table-layout: auto;
    width: 100%;
}

.detailsthead {
    visibility: hidden;
}

.detailsTableLastCol {
    width: 45%;
}

.detailsTableRow {
    vertical-align: top;
}

.productsTable {
    text-align: right;
    margin-top: 1rem;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    border: 1px solid #b3b3b3;
    overflow: hidden;
    table-layout: auto;
    width: 100%;
}

.th, .td {
    padding: 1rem;
}

.thfirst {
    padding: 1rem;
    border-bottom: 1px solid #b3b3b3;
}

.tableTotalCost {
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    text-align: start;
    background-color: #eee;
}

.tableOtherCosts {
    padding: 1rem;
    border-top: 1px solid #b3b3b3;
    text-align: start;
    background-color: #eee;
    font-weight: 400;
}

.thProductName {
    padding: 1rem;
    width: 50%;
    border-bottom: 1px solid #b3b3b3;
}

.tr:nth-of-type(odd) {
    /*background-color: rgb(237 238 242);*/
}

.sellerName {
    font-weight: 700;
    font-size: larger;
}

.result {
    font-size: larger;
    margin-top: 4rem /* 64px */;
}

.invoiceFooter {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    margin-left: 0.5rem;
    padding-right: 5rem;
    width: 100%;
}

.websiteAddress {
    display: flex;
    flex-direction: row;
    letter-spacing: 0.5rem;
    justify-content: flex-end;
    margin-top: 1rem;
}

.receiptFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.signature {
    display: flex;
    flex-direction: column;
}

.signatureImage {
    height: 9rem /* 144px */;
    width: 12rem /* 192px */;
}
