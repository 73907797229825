@charset "utf-8";
@import "../assets/fonts/IRANSans/IRANSans.css";

:root {
    --primary-dark-color: #1A237E;
    --primary-light-color: #1087FF;
    --black-color: black;
    --grey-color: rgba(179, 179, 179, 1);
    --darkGray-color: rgba(76, 76, 76, 1);
    --red-color: rgba(240, 82, 82, 1);
    --client-choice-color: #6366F1;
    --auditor-choice-color: #22C55E;
    --background-grey-color: rgba(240, 242, 245, 1);
    --page-background: #f8f9fa;
    --waiting-color: #EA580C;
    --processing-color: #86198F;
    --completed-color: #115E59;
    --cancelled-color: #90A4AE;
    --returned-color: #9F1239;
    --user-comment-color: #86198F;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--page-background);
}

.contentContainer {
    margin-top: 8vh;
    height: 92vh;
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;
}

.rightSectionContainer {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    transition: margin-left 0.5s ease;
}

.blurred {
    filter: blur(2px);
    pointer-events: none;
}

.notificationContainer {
    position: absolute;
    left: 0;
    top: 0;
    height: 92vh;
    width: 25%;
    background-color: rgba(231, 235, 239, 1);
    transition: left 0.5s ease;
    z-index: 10;
}

/* For not found and coming soon and 500 pages */
.MiscellaneousPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 10vh;
}

.Image {
    width: 30vw;
    height: 25vw;
}

.Heading {
    font-family: IRANSans, serif;
    font-weight: bold;
    color: var(--primary-dark-color);
    font-size: 2vw;
}

.Text {
    font-family: IRANSans, serif;
    direction: rtl;
    font-size: 1.5vw;
}

@media (max-width: 768px) {
    .Image {
        width: 60vw;
        height: 60vw;
    }

    .Heading {
        font-size: 6vw;
    }

    .Text {
        font-size: 4vw;
    }

    .contentContainer {
        margin-top: 10vh;
        height: 90vh;
    }

    .notificationContainer {
        height: 90vh;
    }
}