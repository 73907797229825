.HeaderContainer {
    position: fixed;
    top: 0;
    width: 100%;
    height: 10vh;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.InfoContainer {
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-between;
    padding-top: 5vw;
    padding-bottom: 2.5vw;
}

.leftSection {
    display: flex;
    direction: rtl;
    align-items: center;
    margin-left: 5vw;
}

.rightSection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    margin-right: 5vw;
}

.BellIconContainer {
    position: relative;
    display: inline;
    direction: rtl;
}

.BellIcon {
    font-size: 8vw;
    color: var(--primary-dark-color);
}

.NotificationCount {
    font-family: IRANSansFaNum, serif;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--primary-dark-color);
    color: white;
    border-radius: 50%;
    width: 5vw;
    height: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
    font-weight: bold;
}

.menu_icon {
    cursor: pointer;
    font-size: 8vw;
    color: var(--primary-dark-color);
    margin-right: 3vw;
}

.platformName {
    font-family: IRANSans, serif;
    font-size: 5vw;
    font-weight: bold;
    color: var(--primary-dark-color);
    text-decoration: none;
}

.Backdrop {
    position: fixed;
    top: 12vh;
    left: 0;
    width: 45%;
    height: 100%;
    z-index: 999;
    /* Ensure it's below the menu */
    backdrop-filter: blur(2px);
    /* Apply blur effect */
}