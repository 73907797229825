.LoginContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
}

.LoginContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
    width: 100%;
    background-color: var(--page-background);
}

.HeadingThree,
.HeadingTwo {
    font-family: IRANSans, serif;
    color: var(--primary-dark-color);
}

.HeadingTwo {
    font-size: 8vw;
}

.HeadingThree {
    font-size: 5vw;
}

.AuthForm {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
}

.InputField {
    font-family: IRANSans, serif;
    font-size: 4vw;
    width: 100%;
    margin-bottom: 4vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    padding-right: 2vw;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
}

.ButtonField,
.RegisterButtonField {
    font-family: IRANSans, serif;
    font-size: 4vw;
    font-weight: bold;
    width: 100%;
    margin-top: 8vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    background-color: var(--primary-light-color);
    color: white;
}

.ButtonField:disabled {
    background-color: var(--grey-color);
    color: var(--primary-dark-color);
    cursor: not-allowed;
}

.RegisterButtonField {
    background-color: var(--primary-dark-color);
    width: 80%;
    margin-top: 2vw;
}

.TextField,
.TextLinkField {
    font-family: IRANSans, serif;
    font-size: 4vw;
    color: var(--primary-dark-color);
    text-underline: var(--primary-dark-color);
    border-top: 1px solid var(--primary-dark-color);
    text-align: center;
    width: 95%;
    margin-top: 8vw;
    padding-top: 8vw;
}

.TextLinkField {
    padding-top: 0;
    border-top: none;
    font-weight: bold;
    color: var(--primary-light-color);
    text-decoration: underline;
    cursor: pointer;
}