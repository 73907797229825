.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: IRANSans, serif;
}

.modalContent {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    text-align: right;
}

.modalContent h2 {
    text-align: center;
    color: var(--primary-dark-color);
}

.modalContent p {
    margin-bottom: 20px;
}

.explanationSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.explanationSection h3 {
    margin-bottom: 10px;
    color: var(--primary-dark-color);
}

.inputText {
    font-size: 0.9vw;
    width: 95%;
    margin-bottom: 1vw;
    padding: 0.5vw;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    text-align: right;
    direction: rtl;
    color: black;
    background-color: white;
    font-family: IRANSans, serif;
    min-height: 75px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.cancelButton,
.confirmButton {
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: IRANSans, serif;
    width: 30%;
    font-weight: bold;
}

.cancelButton {
    background-color: #f0f0f0;
    color: #333;
}

.confirmButton {
    background-color: var(--red-color);
    color: white;
}