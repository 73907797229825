.row_mobile {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    direction: rtl;
    padding: 1.5vw 1.5vw;
    font-family: IRANSans, serif;
    font-size: 3.5vw;
    color: var(--primary-dark-color);
}

.row_mobile:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.row_mobile:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.row_desktop {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    direction: rtl;
    padding: 0.5vw 0.5vw;
    font-family: IRANSans, serif;
    font-size: 1vw;
    color: var(--primary-dark-color);
    font-weight: bold;
}

.row_desktop:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.row_desktop:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.border-bottom {
    border-bottom: 1px solid #D8D8D8;
}

.dark-gray-background {
    background-color: rgba(228, 228, 228, 1);
}

.gray-background {
    background-color: rgba(246, 247, 248, 1);
}

.white-background {
    background-color: white;
}

.sorting-arrows {
    padding-right: 1vw;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.arrow-up,
.arrow-down {
    font-size: 1vw;
    line-height: 1.25em;
    user-select: none;
}

.arrow-up:hover,
.arrow-down:hover {
    color: var(--primary-light-color);
}

.inline-input {
    width: 80%;
    padding: 2px 5px;
    border: 1px solid var(--grey-color);
    border-radius: 5px;
    font-family: IRANSans, serif;
    font-size: 1vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.error-field {
    border-radius: 5px;
    box-shadow: 0 0 5px red;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(-10px);
    }

    40% {
        transform: translateX(10px);
    }

    60% {
        transform: translateX(-10px);
    }

    80% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 0.5s ease-in-out;
}