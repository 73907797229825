/*.InquiriesContainer {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    padding: 0 3vw 0vw 3vw;*/
/*    margin-left: auto;*/
/*}*/

.InquiriesContainer {
    display: flex;
    flex-direction: column;
    width: 96%;
    margin: 0 auto;
    height: 100%;
    /* centers the container horizontally */
}

.Header {
    font-family: IRANSans, serif;
    direction: rtl;
    font-size: 1.3vw;
    color: var(--primary-dark-color);
    font-weight: bold;
}

.SearchContainer {
    display: flex;
    flex-direction: row;
    direction: rtl;

    margin-top: 1.5vw;
    margin-right: 0;
    width: 100%;

    /*justify-content: space-between;*/
    height: 3vw;
    align-items: center;
}

.SearchText {
    font-family: IRANSans, serif;
    font-size: 1vw;
    width: 8%;
}

/* FIXME: duplicate with InquiryDropdown in user pages*/
.SearchDropDown {
    direction: rtl;
    font-family: IRANSans, serif;
    color: var(--black-color);

    border-color: var(--grey-color);
    border-radius: 10px;

    width: 10%;
    height: 90%;
    font-size: 1vw;
    padding-right: 1vw;
    margin-left: 1vw;

    background-image: url("../../../assets/images/chevron-down.jpg");
    background-position: left 3px center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.SearchDropDown:focus {
    outline: none;
    border-color: var(--primary-dark-color);
    box-shadow: 0 0 0 2px var(--primary-dark-color);
}

.SearchInput {
    width: 20%;
    height: 80%;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    font-family: IRANSans, serif;
    padding-right: 1vw;
}

.SearchButton {
    cursor: pointer;
    background-color: var(--primary-light-color);
    text-align: center;

    font-family: IRANSans, serif;
    color: white;
    font-size: 1vw;
    font-weight: bold;

    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    margin-right: 2vw;
    width: 10%;
    height: 90%;
}

.TableContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
}

.InquiriesTableContainer {
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    overflow-x: auto;
    margin-top: 3vw;
    right: 0;
}

.detailExpandButton {
    cursor: pointer;
    background-color: var(--primary-light-color);
    text-align: center;
    font-family: IRANSans, serif;
    color: white;
    border-radius: 5px;
    border: 1.5px solid var(--primary-light-color);
    font-size: 0.9vw;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    padding: 2px 5px 2px 5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.PaginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    direction: ltr;
}

.PaginationControls {
    display: flex;
    align-items: center;
}

.PaginationButton {
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
    padding: 8px 16px;
    margin: 0 4px;
    cursor: pointer;
    color: var(--primary-light-color);
    background-color: inherit;
    border: none;
}

.PaginationButton:disabled {
    color: var(--grey-color);
    cursor: not-allowed;
}

.PageNumber {
    padding: 8px 12px;
    margin: 0 4px;
    font-weight: bold;
    font-family: IRANSansFaNum, serif;
    font-size: 1vw;
    color: var(--primary-light-color);
    cursor: pointer;
}

.PageNumber.Active {
    color: var(--grey-color);
}

.ItemCount {
    font-size: 1vw;
    color: var(--grey-color);
    font-weight: bold;
    font-family: IRANSansFaNum, serif;
    direction: rtl;
}

.EmptyInquiriesContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 1vw;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    background-color: white;
}

.EmptyInquiries {
    margin-top: 5vw;
    width: 25%;
    height: 25%;
    font-size: 2vw;
    color: var(--primary-light-color);
    margin-bottom: 1vw;
}

.EmptyInquiriesMessage {
    font-family: IRANSans, serif;
    direction: rtl;
    color: var(--primary-dark-color);
    margin-bottom: 5vw;
    font-weight: bold;
}

.PaginationWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}