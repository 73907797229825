.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}

.modal {
    position: relative;
    background-color: white;
    border-radius: 8px;
    padding: 1.5vw;
    width: 80%;
    height: 75%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    direction: rtl;
}

.show {
    opacity: 1;
}

.HeadingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.closeIcon {
    cursor: pointer;
    font-size: 2vw;
    color: var(--black-color);
    width: 5%;
}

.ReceiptNameAndChoice {
    display: flex;
    gap: 1vw;
    flex-grow: 1;
    justify-content: center;
}

.ReceiptName {
    font-family: IRANSansFaNum, serif;
    font-weight: bold;
    color: var(--darkGray-color);
    font-size: 1vw;
}

.ClientChoiceIcon {
    padding: 0 0.5vw;
    background-color: var(--client-choice-color);
    color: white;
    border-radius: 5px;
    z-index: 1;
    font-size: 0.8vw;
    font-family: IRANSans, serif;
    font-weight: bold;
    align-items: center;
    display: flex;
}

.ReceiptProcessingModalContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    direction: rtl;
    overflow-y: scroll;
}

.ReceiptProcessingModalContainer>* {
    max-width: 100%;
}

.LeftSectionContainer,
.RightSectionContainer {
    display: flex;
    flex-direction: column;
    width: 49.5%;
    height: 95%;
    gap: 1vw;
}

.Receipt {
    width: 100%;
    height: 100%;
    max-height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    overflow-x: hidden;
}

.ReceiptPhoto {
    width: 100%;
}

.StoreAndPaymentInfo,
.ProductsInfo,
.CostsInfo,
.AdditionalAndTotalContainer,
.ChooseProductContainer {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    height: 45%;
    width: 90%;
    padding: 1vw;
    overflow-y: auto;
}

.ProductsInfo {
    height: 85%;
}

.CostsInfo {
    flex-direction: row;
    height: 7%;
    width: 100%;
    justify-content: space-between;
    padding: 0.5vw 0;
    gap: 1vw
}

.AdditionalAndTotalContainer {
    height: 15%;
    justify-content: space-between;
}

.ChooseProductContainer {
    height: 100%;
    overflow-y: auto;
}

.FieldContainer,
.CostsFieldContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vw;
}

.FieldContainer:last-child {
    margin-bottom: 0;
}

.CostsFieldContainer {
    margin-right: 0;
    flex-direction: row;
    margin-bottom: 0;
}

.CostsFieldContainer:first-child {
    width: 30%;
    margin-right: 1vw;
}

.CostsFieldContainer:last-child {
    margin-left: 1vw;
    width: 30%;
}

.CostsFieldContainer:nth-child(2) {
    width: 20%;
}

.heading,
.Subheading,
.AdditionalCostsSubheading {
    font-family: IRANSans, serif;
    font-weight: bold;
    font-size: 1vw;
}

.heading {
    width: 100%;
    color: var(--grey-color);
    margin-bottom: 1vw;
}

.Subheading {
    width: 35%;
    color: var(--darkGray-color);
}

.ChooseProductModalDropdown {
    cursor: pointer;
    direction: rtl;
    font-family: IRANSans, serif;
    color: var(--grey-color);
    border-color: var(--grey-color);
    border-radius: 10px;
    width: 65%;
    font-size: 1vw;
}

.InputField,
.AdditionalCostsInputField,
.CostsInputField {
    width: 65%;
    height: 2.5vw;
    border-radius: 10px;
    border: 1.5px solid var(--grey-color);
    font-family: IRANSans, serif;
    font-size: 0.9vw;
    box-sizing: border-box;
    padding: 0.5vw;
}

.CostsInputField {
    /*width: 50%;*/
}

.SelectDropdown {
    width: 65%;
    height: 2.5vw;
    border-radius: 10px;
    border: 1.5px solid var(--grey-color);
    font-family: IRANSans, serif;
    font-size: 1.1vw;
    padding-right: 1vw;
    background-image: url("../../../assets/images/chevron-down.jpg");
    background-position: left 10px center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    cursor: pointer;
}

.SelectOption {
    font-family: IRANSans, serif;
    color: var(--darkGray-color);
    width: 100%;
}

.InquiryHistoryTimeLine {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    overflow-y: scroll;
    padding: 1vw;
    height: 60%;
    width: 90%;
}

.ActionButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    margin-top: 1vw;
}

.NextButton,
.BackButton {
    background-color: var(--primary-light-color);
    align-self: center;
    text-align: center;
    cursor: pointer;
    font-family: IRANSans, serif;
    color: white;
    font-size: 1vw;
    font-weight: bold;
    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    padding-right: 1vw;
    padding-left: 1vw;
    height: 2.5vw;
}

.BackButton {
    border: 1.5px solid var(--red-color);
    color: var(--red-color);
    background-color: white;
}

.NextButton:disabled,
.BackButton:disabled {
    border-color: var(--grey-color);
    cursor: not-allowed;
    color: white;
    background-color: var(--grey-color);
}

.ReceiptProductsContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.ReceiptProductsTable {
    display: flex;
    width: 100%;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    margin-bottom: 1vw;
}

.Icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1vw;
}

.EditIcon {
    color: var(--primary-dark-color);
    font-size: 1vw;
    cursor: pointer;
}

.TrashIcon {
    color: var(--red-color);
    font-size: 1vw;
    cursor: pointer;
}

.EmptyTableContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.EmptyTableImage {
    height: 10vw;
    width: 8vw;
    margin-bottom: 2vw;
}

.ExplanationText {
    font-family: IRANSans, serif;
    font-size: 0.9vw;
}

.AddProduct {
    background-color: white;
    text-align: center;
    cursor: pointer;
    font-family: IRANSans, serif;
    color: var(--primary-light-color);
    font-size: 1vw;
    font-weight: bold;
    border-radius: 10px;
    border: 1.5px dashed var(--primary-light-color);
    width: 50%;
    height: 2vw;
    align-self: center;
    margin-top: auto;
    margin-bottom: 1vw;
}

.uploadedPdf {
    width: 85%;
    height: 10vw;
    object-fit: cover;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.pdfIcon {
    font-size: 5vw;
    align-self: center;
    color: var(--red-color);
}

.pdfText {
    font-size: 1vw;
    color: #333;
    font-family: IRANSans, serif;
}

.TotalPrice {
    font-family: IRANSans, serif;
    font-weight: bold;
    font-size: 1.1vw;
    color: var(--primary-dark-color);
    margin-right: 1vw;
    align-self: center;
}

.AdditionalCostsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5vw;
}

.AdditionalCostsInputField {
    width: 60%;
}

.AdditionalCostsSubheading {
    width: 40%;
    color: var(--darkGray-color);
}

.ChooseButtons {
    display: flex;
    gap: 1.5vw;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5vw;
}

.ChoiceButton {
    background-color: white;
    color: var(--primary-light-color);
    text-align: center;
    cursor: pointer;
    font-family: IRANSans, serif;
    font-size: 0.9vw;
    font-weight: bold;
    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    padding-right: 1vw;
    padding-left: 1vw;
    height: 2.5vw;
}

.ChoiceButton:disabled {
    color: white;
    background-color: var(--primary-light-color);
}

.SearchContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    /*margin-top: 1vw;*/
    width: 100%;
}

.SearchProductsContainer {
    display: flex;
    flex-direction: column;
}

.SearchField {
    display: flex;
    gap: 1vw;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vw;
    margin-top: 0.5vw;
}

.SearchInput {
    width: 70%;
    height: 2vw;
    border-radius: 10px;
    border: 1.5px solid var(--grey-color);
    font-family: IRANSans, serif;
    padding-right: 1vw;
    align-self: center;
}

.SearchButton {
    width: 30%;
    background-color: white;
    color: var(--primary-light-color);
    text-align: center;
    cursor: pointer;
    font-family: IRANSans, serif;
    font-size: 0.9vw;
    font-weight: bold;
    border-radius: 10px;
    border: 1.5px solid var(--primary-light-color);
    padding-right: 1vw;
    padding-left: 1vw;
    height: 2.5vw;
    align-self: center;
}

.SearchedProductsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    width: 100%;
    overflow-y: auto;
    margin-top: 1vw;
}

.ProductBox {
    cursor: pointer;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    padding: 0.5vw;
    margin-bottom: 0.5vw;
    font-size: 1vw;
    font-family: IRANSans, serif;
}

.errorField {
    border-radius: 5px;
    box-shadow: 0 0 5px red;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(-10px);
    }

    40% {
        transform: translateX(10px);
    }

    60% {
        transform: translateX(-10px);
    }

    80% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake {
    animation: shake 0.5s ease-in-out;
}

.ProductInfoExpansionContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vw;
    margin-top: 1vw;
    padding: 0.5vw;
}

.ProductInfoExpansion {
    font-family: IRANSans, serif;
    font-size: 1vw;
}

.inlineInput {
    width: 60px;
    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.loading_container {
    display: grid;
    place-items: center;
    height: 100%;
}