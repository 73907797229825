/*.InquiryDetailsContainer {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    padding: 0 3vw 0 3vw;*/
/*    direction: rtl;*/
/*    width: 92%;*/
/*    margin-left: auto;*/
/*    !* height: 92vh; *!*/
/*}*/

.InquiryDetailsContainer {
    display: flex;
    flex-direction: column;
    width: 96%;
    direction: rtl;
    margin: 0 auto;
    height: 100%;
    /* centers the container horizontally */
}

.NameAndStatusContainer {
    display: flex;
    align-items: center;
    gap: 4vw;
}

.InquiryName {
    font-family: IRANSans, serif;
    /*font-weight: bold;*/
    font-size: 1.2vw;
    color: var(--primary-dark-color);
}

.PageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1vw;
    /*height: 100%;*/
}

.ColumnsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 72vh;
    margin-top: 1vw;
}

.FirstRow,
.SecondRow,
.ThirdColumn {
    display: flex;
    flex-direction: column;
}

.ThirdColumn {
    gap: 1vw;
}

.FirstCol {
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    gap: 1vw;
}

.FirstRow {
    width: 100%;
    height: 32%;
    flex-direction: row;
}

.SecondRow {
    width: 100%;
    height: 63%;
    flex-direction: row;
}

.ThirdColumn {
    width: 25%;
}

.InquiryDetails,
.Product,
.UnProcessedReceipts,
.ProcessedReceipts,
.Explanation,
.InquiryHistoryTimeLine,
.AddingCommentContainer {
    display: flex;
    flex-direction: column;
    border: 0.5px solid var(--grey-color);
    border-radius: 10px;
    margin-left: 1vw;
    background-color: white;
}

.InquiryDetails {
    width: 35%;
    height: 100%;
    overflow-y: scroll;
}

.Product {
    width: 65%;
    overflow-y: scroll;
    height: 100%;
}

.UnProcessedReceipts {
    width: 35%;
    overflow-y: scroll;
    height: 100%;
}

.ProcessedReceipts {
    width: 65%;
    height: 100%;
}

.ProcessedReceipts.FullSize {
    width: 100%;
    height: 100%;
}

.Explanation {
    width: 100%;
    margin-bottom: 2vw;
}

.InquiryHistoryTimeLine {
    width: 100%;
    overflow-y: scroll;
    height: 65%;
}

.AddingCommentContainer {
    width: 100%;
    height: 30%;
}

.AuditorInquiryPageHeader {
    font-family: IRANSans, serif;
    font-size: 1.1vw;
    font-weight: bold;
    color: var(--primary-dark-color);
    padding-bottom: 1vw;
    padding-top: 1vw;
    padding-right: 1.5vw;
}

.AuditorInquiryInputField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin-right: 1.5vw;
}

.InputText {
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: normal;
    margin-bottom: 0.5vw;
    color: var(--darkGray-color);
}

.InputAnswer,
.ExplanationText,
.ExplanationTextFull {
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: normal;
    margin-bottom: 0.5vw;
    color: var(--darkGray-color);
}

.ExplanationText,
.ExplanationTextFull {
    margin-right: 0.5vw;
    margin-left: 0.5vw;
    margin-bottom: 1vw;
    font-size: 0.9vw;
}

.ExplanationTextFull {
    margin-right: 1.5vw;
}

.ProductNameText {
    font-family: IRANSans, serif;
    font-size: 1.2vw;
    font-weight: bold;
    color: var(--darkGray-color);
    margin-right: 1.5vw;
}

.ProductDetailText {
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
    color: var(--darkGray-color);
    margin-right: 1.5vw;
    margin-bottom: 0.5vw;
}

.ProductDetailText:last-child {
    margin-bottom: 1.5vw;
}

.ReceiptBox {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 1.5vw;
    margin-top: 1vw;
    width: 85%;
    height: 3vw;
    border: 0.5px solid var(--grey-color);
    border-radius: 5px;
    padding-right: 0.5vw;
    align-items: center;
}

.ReceiptBox:last-child {
    margin-bottom: 1vw;
}

.ReceiptNumber {
    font-family: IRANSansFaNum, serif;
    font-size: 1vw;
    color: var(--primary-dark-color);
    font-weight: bold;
}

.ChoicesContainer {
    display: flex;
    flex-direction: column;
    gap: 0.2vw;
}

.myChoiceText,
.auditorChoiceText {
    padding: 0.2vw 0.5vw;
    background-color: var(--client-choice-color);
    color: white;
    border-radius: 5px;
    z-index: 1;

    font-size: 0.6vw;
    font-family: IRANSans, serif;
    font-weight: bold;

    align-items: center;
    display: flex;
}

.auditorChoiceText {
    background-color: var(--auditor-choice-color);
}

.ArrowIcon {
    font-size: 1.5vw;
    font-weight: normal;
    color: var(--primary-dark-color);
    margin-left: 1.5vw;
}

.EmptyTableContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.EmptyTableImage {
    height: 12vw;
    width: 10vw;
    margin-bottom: 2vw;
}

.InquiriesTableContainer {
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    overflow-x: auto;
    margin-top: 1vw;
    margin-bottom: 1vw;
    width: 95%;
    align-self: center;
}

.Circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--grey-color);
    cursor: pointer;
}

.Circle.disabled {
    cursor: not-allowed;
    color: var(--grey-color);
}

.Icons {
    display: flex;
    gap: 0.5vw;
}

.EyeIcon,
.EditIcon,
.ShowReceiptIcon,
.ExpandIcon,
.DownloadIcon {
    cursor: pointer;
    font-size: 1.2vw;
}


.enlargedImageContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.enlargedImage {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    cursor: pointer;
    border-radius: 10px;
}

.Buttons {
    display: flex;
    flex-direction: row;
}

.CompleteProcessing,
.ApproveProcessing,
.BackButton,
.AddSuggestion,
.ReturnButton,
.SendButton {
    cursor: pointer;
    background-color: var(--primary-light-color);
    text-align: center;
    font-family: IRANSans, serif;
    color: white;
    font-size: 1vw;
    font-weight: bold;
    border-radius: 5px;
    border: 1.5px solid var(--primary-light-color);
    margin-left: 1vw;
    width: 15%;
    height: 3vw;
}

.BackButton {
    background-color: white;
    color: var(--primary-light-color);
}

.ReturnButton {
    background-color: var(--red-color);
    color: white;
    border: 1.5px solid var(--red-color);
    margin-right: auto;
}

.ReturnButton:disabled {
    background-color: var(--grey-color);
    border: 1.5px solid var(--grey-color);
    color: white;
}

.AddSuggestion {
    margin-right: 1.5vw;
    width: 25%;
    margin-bottom: 1vw;
}

.CompleteProcessing.disabled {
    cursor: not-allowed;
    background-color: var(--grey-color);
    border: 1.5px solid var(--grey-color);
}

.ApproveProcessing {
    background-color: var(--primary-dark-color);
    border: 1.5px solid var(--primary-dark-color);
}

.SendButton {
    align-self: center;
    width: 90%;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 1vw;
    height: 2.5vw;
}

.SendButton:disabled {
    background-color: var(--grey-color);
    color: var(--primary-dark-color);
    cursor: not-allowed;
}

.commentInputField,
.explanationInputField {
    height: 70%;
    width: 85%;
    align-self: center;
    padding: 0.5vw;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    text-align: right;
    direction: rtl;
    color: black;
    font-family: IRANSans, serif;
    margin-bottom: 1vw;
    font-size: 0.9vw;
}

.explanationInputField {
    width: 95%;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container>div {
    border: none;
    /* Ensure no border for expanded table */
}

.Icons {
    display: flex;
    gap: 1vw;
}

.loading_container {
    display: grid;
    place-items: center;
    height: 100%;
}

.downloadOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
