.success-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.success-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    direction: rtl;
}

.success-icon {
    height: 60px;
    width: 60px;
    color: green;
}

.success-message {
    font-size: 4vw;
    /* margin-bottom: 2vw; */
    color: var(--primary-dark-color);
    font-family: IRANSans, serif;
    font-weight: bold;
    text-align: center;
}

.success-detail-message {
    font-size: 3vw;
    margin-bottom: 4vw;
    color: var(--primary-dark-color);
    font-family: IRANSans, serif;
    text-align: center;
}

.success-button {
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    font-size: 3.5vw;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: IRANSans, serif;
    font-weight: bold;
    width: 85%;
    align-self: center;
}

.success-button.confirm {
    background-color: var(--primary-light-color);
    color: white;
}

@media (min-width: 481px) {
    .success-message {
        font-size: 1.3vw;
    }

    .success-detail-message {
        font-size: 1vw;
    }

    .success-button {
        font-size: 1.1vw;
    }
}

@media (max-width: 480px) {
    .success-content {
        width: 75%;
    }

    .success-button {
        /* margin-bottom: 10px; */
        width: 90%;
    }
}