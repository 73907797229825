.NotificationSectionContainer {
    position: fixed;
    left: 0;
    top: 9vh;
    width: 100%;
    height: 100vh;
    background-color: rgba(231, 235, 239, 1);
    transition: left 0.5s ease;
    z-index: 0;
    animation: none;
    overflow-y: scroll;
}

@keyframes slideIn {
    from {
        left: -69%;
        /* Start position */
    }

    to {
        left: 0;
        /* End position */
    }
}

.NotificationSectionContainer.open {
    left: 0;
    animation: slideIn 0.5s forwards;
}

.NotificationSectionContent {
    border-top: 1px solid var(--grey-color);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 6vw 6vw 50vw;
}

.MobileHeading {
    font-family: IRANSans, serif;
    font-size: 5vw;
    font-weight: bold;
    align-self: center;
    color: var(--primary-dark-color);
    margin-bottom: 3vw;
}

.NotificationItem {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    border: 1px solid var(--grey-color);
    height: 20vw;
    background-color: white;
    direction: rtl;
    margin-bottom: 2vw;
    align-items: center;
    padding-right: 5vw;
}

.NotificationItem.unread {
    border-right: 6px solid var(--primary-dark-color);
}

.ItemIcon {
    margin-left: 3vw;
    margin-right: 1vw;
    font-size: 6vw;
    color: var(--primary-light-color);
    height: 100%;
}

.ItemContent {
    display: flex;
    flex-direction: column;
}

.ItemHeader {
    font-family: IRANSans, serif;
    font-size: 3.5vw;
    direction: rtl;
    font-weight: bold;
    padding-left: 2vw;
    color: var(--primary-dark-color);
    padding-top: 2vw;
}

.ItemTime {
    font-family: IRANSans, serif;
    font-size: 3vw;
    direction: rtl;
    padding-top: 2vw;
    padding-bottom: 2vw;
    font-weight: bold;
    color: var(--grey-color);
}

.MobileReadAllButton {
    text-decoration: none;
    color: white;
    font-family: IRANSans, serif;
    font-weight: bold;
    font-size: 4.5vw;

    position: fixed;
    bottom: 5vw;
    width: 90%;
    background-color: var(--primary-light-color);
    border: 1.5px solid var(--primary-light-color);
    text-align: center;
    border-radius: 10px;
    padding-top: 3vw;
    padding-bottom: 3vw;
}

.MobileReadAllButton:disabled {
    background-color: var(--grey-color);
    border: 1.5px solid var(--grey-color);
}